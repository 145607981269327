import React from 'react';

class Login extends React.Component {
    render() {
        return (
            <nav className={"login"}>
                {/*<div className={"flex-element image"}>*/}
                {/*    /!*<img src="https://plumbr.io/app/uploads/2015/01/thread-lock.jpeg" alt=""/>*!/*/}
                {/*</div>*/}
                <div className="flex-element">
                    <div className="image">
                        <img src="https://plumbr.io/app/uploads/2015/01/thread-lock.jpeg" alt=""/>
                    </div>
                    <button className={" btn btn-primary"} onClick={() => this.props.authenticate('Github')}>Logg inn med GitHub</button>
                </div>
            </nav>
        );
    }
}

export default Login;