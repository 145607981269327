// This is just some sample data so you don't have to think of your own!
const expenses = {
    expense1: {
        expenseType: "fuel",
        desc: "fylte diesel",
        amount: "432",
        date: "12-04-2018"
    },

    expense2: {
        expenseType: "loan",
        desc: "betalte avdrag",
        amount: "2516",
        date: "15-04-2018"
    },

    expense3: {
        expenseType: "workshop",
        desc: "NAF test",
        amount: "1890",
        date: "25-08-2018"
    },

    expense4: {
        expenseType: "div",
        desc: "spylerveske",
        amount: "99",
        date: "13-10-2019"
    },

    expense5: {
        expenseType: "fuel",
        desc: "fylte diesel",
        amount: "602",
        date: "12-04-2019"
    },

    expense6: {
        expenseType: "loan",
        desc: "betalte avdrag",
        amount: "2516",
        date: "15-04-2019"
    },

    expense7: {
        expenseType: "workshop",
        desc: "bytte bremser",
        amount: "1489",
        date: "12-06-2019"
    },

    expense8: {
        expenseType: "div",
        desc: "nye vindusviskere",
        amount: "229",
        date: "02-02-2019"
    },

    expense9: {
        expenseType: "fuel",
        desc: "fylte diesel",
        amount: "549",
        date: "29-07-2019"
    }
};

export default expenses;
