import React from 'react';
import AddExpenseForm from "./AddExpenseForm";

class AddExpense extends React.Component {

    state = {
      typeActive: ""
    };

    renderOptions = (type) => {
        if (type) {
            this.setState({typeActive: type});

        }
    };

    render() {

        const currentType = this.state.typeActive;
        let form;
        currentType !== "" ? form =  <AddExpenseForm type={currentType} addExpense={this.props.addExpense}/>: form = <p>Velg hvilken type kostnad du vil legge inn</p>;

        return (
            <div className="container">
                <div className={"row"}>
                    <div className="col-sm-6">
                        <h2>Legg inn kostnad</h2>
                        <button className={"btn btn-primary"} onClick={() => this.renderOptions("fuel")}>Drivstoff</button>
                        <button className={"btn btn-primary"} onClick={() => this.renderOptions("loan")}>Lån</button>
                        <button className={"btn btn-primary"} onClick={() => this.renderOptions("div")}>Diverse</button>
                        <button className={"btn btn-primary"} onClick={() => this.renderOptions("workshop")}>Verksted</button>
                    </div>
                    <div className="col-sm-6">
                        {form}
                    </div>
                </div>
                <div className="add-expense">
                </div>
            </div>
        );
    }
}

export default AddExpense;