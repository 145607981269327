import React from 'react';
import Expense from './Expense';

class AllExpenses extends React.Component {


    sortByDate = () => {
        const expenses = this.props.expenses;
        // console.log(expenses);
        const dateSorted = Object.keys(expenses).sort((a,b) => {
            const [aDay,aMonth, aYear] =  expenses[a].date.split('-');
            const [bDay,bMonth, bYear] =  expenses[b].date.split('-');
            // console.log(aYear);
            if (aYear-bYear !== 0){ return bYear-aYear }
            else if (aMonth-bMonth !== 0){ return bMonth-aMonth}
            else { return bDay-aDay}
        }).map(key => expenses[key]);
        // console.log(expenses);
        // console.log(dateSorted);
        return dateSorted;
    };

    render() {
        const sortedByDate = this.sortByDate();
        // console.log(sortedByDate)

        return (

            <div className="container text-right">
                <button onClick={this.sortByDate}>TEST</button>
                <h3 className={"text-center"}>Alle Kostnader</h3>
                <div className="row">
                    <div className="col-sm-3">
                        <h4>Dato</h4>
                    </div>
                    <div className="col-sm-3">
                        <h4>Type</h4>
                    </div>
                    <div className="col-sm-3">
                        <h4>Beskrivelse</h4>
                    </div>
                    <div className="col-sm-3">
                        <h4>Kostnad</h4>
                    </div>
                </div>
                {Object.keys(sortedByDate).map(key => <Expense key={key} details={sortedByDate[key]} index={key}></Expense>)}
            </div>

        );
    }
}

export default AllExpenses;