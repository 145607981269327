import React from 'react';

class AddExpenseForm extends React.Component {

    getDateToday = () => {
        const today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth()+1;
        const yyyy = today.getFullYear();
        return (dd + "-" + mm + "-" + yyyy);
    };

    state = {
        expenseType: "",
        desc: "",
        amount: "",
        liters: "",
        kilom: "",
        date: this.getDateToday()
    };




    renderFuelInput = () => {
        return (
            <>
                <label><strong>Antall liter</strong>
                    <input name={"liters"} value={this.state.liters} onChange={this.handleChange} type="text"/>
                </label>
                <label><strong>Kilometerstand</strong>
                    <input name={"kilom"} value={this.state.kilom} onChange={this.handleChange} type="text"/>
                </label>
            </>
        );
    };

    handleChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value, expenseType: this.props.type});
        if(this.state.date === ''){
            this.setState({date: this.getDateToday()});
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.addExpense(this.state);
        event.currentTarget.reset();
    };


    render() {
        const isFuel = this.props.type === "fuel";
        // console.log(this.state);

        let fuelForm;
        if (isFuel) {
            fuelForm = this.renderFuelInput();
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <h4>Legg in drivstoffkostnad</h4>
                <label><strong>Beskrivelse</strong>
                    <input name={"desc"} value={this.state.desc} onChange={this.handleChange} required type="text"/>
                </label>
                <label><strong>Beløp</strong>
                    <input name={"amount"} value={this.state.amount} onChange={this.handleChange} required type="text"/>
                </label>
                <input name={"date"} type={"text"} onChange={this.handleChange} value={this.state.date} />
                <input name={"expenseType"} type="hidden" value={this.state.expenseType}/>
                {isFuel ? fuelForm: null}
                <button className={"btn btn-primary"}>Legg inn</button>
            </form>

        );
    }
}

export default AddExpenseForm;