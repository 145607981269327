import React from 'react';

class TotalCost extends React.Component {


    calcTotal = () => {
        return Object.keys(this.props.expenses).reduce((sum,key) => {
            return sum + parseFloat(this.props.expenses[key].amount);
        },0);
    };

    calcEach = (type) => {
        return Object.keys(this.props.expenses).reduce((sum,key) => {
            if (this.props.expenses[key].expenseType === type) {
                return sum + parseFloat(this.props.expenses[key].amount);
            }
            return sum;
        },0);
    };
    //
    // calcMonth = (month) => {
    //     const avgMonth =  Object.keys(this.props.expenses)
    // }



    // sortByDate = () => {
    //     const expenses = this.props.expenses;
    //     // console.log(expenses);
    //     const dateSorted = Object.keys(expenses).sort((a,b) => {
    //         const [aDay,aMonth, aYear] =  expenses[a].date.split('-');
    //         const [bDay,bMonth, bYear] =  expenses[b].date.split('-');
    //         // console.log(aYear);
    //         if (aYear-bYear !== 0){ return bYear-aYear }
    //         else if (aMonth-bMonth !== 0){ return bMonth-aMonth}
    //         else { return bDay-aDay}
    //     }).map(key => expenses[key]);
    //     // console.log(expenses);
    //     // console.log(dateSorted);
    //     return dateSorted;
    // };


    render() {

        return (
          <div className={"container text-center"}>
              <h2>Total Kostnad: {this.calcTotal()} kr</h2>
            <div className="row">
                <div className="col-sm-3">
                    <h3>Drivstoff</h3>
                    <p>{this.calcEach("fuel")} kr</p>
                </div>
                <div className="col-sm-3">
                    <h3>Lån</h3>
                    <p>{this.calcEach("loan")} kr</p>
                </div>
                <div className="col-sm-3">
                    <h3>Diverse</h3>
                    <p>{this.calcEach("div")} kr</p>
                </div>
                <div className="col-sm-3">
                    <h3>Verksted</h3>
                    <p>{this.calcEach("workshop")} kr</p>
                </div>
            </div>
            <div className="row">

            </div>
          </div>

        );
    }
}

export default TotalCost;