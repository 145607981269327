import React from 'react';

class Expense extends React.Component {
    render() {
        let type = '';
        switch (this.props.details.expenseType) {
            case "div":
                type = 'Diverse';
                break;
            case "fuel":
                type = 'Diesel';
                break;
            case "loan":
                type = 'Lån';
                break;
            case "workshop":
                type = 'Verksted';
                break;
            default: break;
        }

        return (
            <div className="row">
                <div className="col-sm-3 text-right">
                    {this.props.details.date}
                </div>
                <div className="col-sm-3 text-right">
                    {type}
                </div>
                <div className="col-sm-3 text-right">
                    {this.props.details.desc}
                </div>
                <div className="col-sm-3 text-right">
                    <strong>{this.props.details.amount}</strong> kr
                </div>
            </div>
        );
    }
}

export default Expense;