import React from 'react';
import '../App.css';
import sampleExpenses from '../sample-expenses';
import AddExpense from './AddExpense';
import AllExpenses from './AllExpenses';
import TotalCost from './TotalCost';
import Login from './Login';
import base, { firebaseApp } from "../base";
import firebase from 'firebase';

class App extends React.Component {

  state = {
    expenses: {},
    uid: null,
    owner: null
  };

  componentDidMount() {
      this.ref = base.syncState('state/expenses', {
          context: this,
          state: 'expenses'
      });
      firebase.auth().onAuthStateChanged(user => {
          if (user) {
              this.authHandler({ user });
          }
      })
  };

  componentWillUnmount() {
      base.removeBinding(this.ref);
  }

  addExpense = (expense) => {
      const expenses = { ...this.state.expenses };
      expenses[`expense ${Date.now()}`] = expense;
      this.setState({ expenses });
  };

  loadSampleExpenses = () => {
      this.setState({expenses: sampleExpenses});
  };



  authHandler = async (authData) => {
      const database = await base.fetch('state', { context: this });
      // console.log(authData);
      if (!database.owner) {
          await base.post('state/owner', {
              data: authData.user.uid
          });
      }

      this.setState({
          uid: authData.user.uid,
          owner: database.owner || authData.user.uid
      });
  };

  authenticate = () => {
      console.log("authenticating user..");
      const authProvider = new firebase.auth.GithubAuthProvider();
      firebaseApp.auth().signInWithPopup(authProvider).then(this.authHandler);

  };

  logout = async () => {
      console.log('logging out!');
      await firebase.auth().signOut();
      this.setState({ uid: null});
};

  render() {

      const logout = <button className={"btn btn-primary"} onClick={this.logout}>Logg ut</button>;

      if (!this.state.uid) {
          return <Login authenticate={this.authenticate}/>;
      }

      if (this.state.uid !== this.state.owner) {
          return (
              <div>
                <p>Uautorisert bruker</p>
                  {logout}
              </div>
          )
      }

      return (
        <div>
            <br/>
            {logout}
            <br/>
          <h1 className={"text-center"}>Kostnadsapp for bil</h1>
            <br/>
          <AddExpense addExpense={this.addExpense} loadSampleExpenses={this.loadSampleExpenses}/>
            <br/>
            <TotalCost expenses={this.state.expenses}/>
            <br/>
            <AllExpenses expenses={this.state.expenses}/>
            <br/>
        </div>
    );
  }
}

export default App;
