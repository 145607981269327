import React from 'react';

class ChooseCar extends React.Component {
    render() {
        return (
            <div>
                <br/>
                <h1 className={"text-center"}>Min Bil App</h1>
                <br/>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-3" onClick={this.goToCar}>
                            <div className={"start-page"}></div>
                        </div>
                        <div className="col-sm-3">
                            <ul>
                                <li><strong>Volkswagen Polo TDI 2012</strong></li>
                                <li>Registreringsnummer: PD39985</li>
                            </ul>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ChooseCar;