import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBkXonuONyMD6d5c9XiPijD3CQtGlM4Rx8",
    authDomain: "react-bil-app-bfcc4.firebaseapp.com",
    databaseURL: "https://react-bil-app-bfcc4.firebaseio.com",
    projectId: "react-bil-app-bfcc4",
    storageBucket: "",
    messagingSenderId: "413015618365",
    appId: "1:413015618365:web:779291962563a44f",
});

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp };

export default base;